<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  computed: {
  },
  mounted () {
  }
}
document.addEventListener('DOMContentLoaded', () => {
  const html = document.querySelector('html')
  let fontSize = window.innerWidth / 10
  fontSize = fontSize > 50 ? 50 : fontSize
  html.style.fontSize = fontSize + 'px'
})
</script>
<style lang="scss" scoped>
@import "./assets/styles/global.scss";
#app {
  width: 100%;
  height: 100%;
  background: #7d8188;
  /* background: #A9A9A9; */
  overflow: hidden;
}
</style>
