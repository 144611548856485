import Vue from 'vue'
import VueRouter from 'vue-router'

const Ebook = () => import('../views/ebook')
const EbookReader = () => import('../components/ebook/EbookReader.vue')

const Store = () => import('../views/store/index.vue')
const home = () => import('../views/store/StoreHome.vue')
const List = () => import('../views/store/StoreList.vue')
const Detail = () => import('../views/store/StoreDetail.vue')
const Shelf = () => import('../views/store/StoreShelf.vue')
const Category = () => import('../views/store/StoreCategory.vue')
const Speaking = () => import('../views/store/StoreSpeaking.vue')

const Guide = () => import('../views/guide/index.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/guide'
  },
  {
    path: '/ebook',
    component: Ebook,
    children: [
      {
        path: ':fileName',
        component: EbookReader
      }
    ]
  },
  {
    path: '/store',
    component: Store,
    redirect: '/store/home',
    children: [
      {
        path: 'shelf',
        component: Shelf
      },
      {
        path: 'category',
        component: Category
      },
      {
        path: 'home',
        component: home
      },
      {
        path: 'list',
        component: List
      },
      {
        path: 'detail',
        component: Detail
      },
      {
        path: 'speaking',
        component: Speaking
      }
    ]
  },
  {
    path: '/guide',
    component: Guide
  }
]

const router = new VueRouter({
  routes
})

export default router
