const messages = {
  home: {
    title: 'Book Store',
    hint: 'Computer Science And Software Engineering',
    guessYouLike: 'Guess You Like',
    change: 'Change',
    clear: 'Clear',
    hotSearch: 'Hot Search',
    historySearch: 'History Search',
    sameAuthor: 'Same author with $1',
    sameReader: 'Same reader with $1',
    readPercent: '$1 is reading $2',
    recommend: 'Recommend',
    seeAll: 'See all',
    readers: '$1 is reading',
    featured: 'Featured',
    category: 'Category',
    books: 'books',
    readNow: 'Read Now',
    allBook: '$1 books'
  },
  category: {
    computerScience: 'Computer Science',
    socialSciences: 'Social Sciences',
    economics: 'Economics',
    education: 'Eductation',
    engineering: 'Engineering',
    environment: 'Environment',
    geography: 'Geography',
    history: 'History',
    laws: 'Laws',
    lifeSciences: 'LifeSciences',
    literature: 'Literature',
    biomedicine: 'Biomedicine',
    businessandManagement: 'Business and Management',
    earthSciences: 'Earth Sciences',
    materialsScience: 'Materials Science',
    mathematics: 'Mathematics',
    medicineAndPublicHealth: 'Medicine And Public Health',
    philosophy: 'Philosophy',
    physics: 'Physics',
    politicalScienceAndInternationalRelations: 'Political Science And International Relations',
    psychology: 'Psychology',
    statistics: 'Statistics'
  },
  shelf: {
    title: 'Book Shelf',
    edit: 'Edit',
    cancel: 'Cancel',
    search: 'Search',
    private: 'Private',
    noPrivate: 'Close Private',
    download: 'Download',
    move: 'Move...',
    remove: 'Remove',
    setPrivateTitle: 'When opened, the reading history of selected books will not be made public',
    open: 'Open',
    closePrivateTitle: 'Whether to close the private reading of selected books?',
    close: 'Close',
    setPrivateSuccess: 'Private reading has been open and reading history will no longer be published',
    closePrivateSuccess: 'Private reading has been closed',
    setDownloadTitle: 'When opened, selected books will automatically download',
    setDownloadSuccess: 'Opened, will automatically download purchased books',
    setDownloadError: 'Offline download exception, please try again',
    removeDownloadTitle: 'Once confirmed, the offline books of selected will be remove',
    removeDownloadSuccess: 'Offline books of selected has been remove',
    delete: 'Remove',
    clearCache: 'Clear Cache',
    clearCacheSuccess: 'Clear cache successfully, cache is empty',
    removeBookTitle: 'Whether to remove $1 out of the bookshelf?',
    removeBook: 'Remove',
    selectedBooks: 'selected books',
    default: 'Default',
    progress: 'By Progress',
    purchase: 'By Purchase',
    bought: 'Bought',
    notPurchased: 'Not Purchased',
    selectBook: 'Select Book',
    haveSelectedBook: '$1 book has been selected',
    haveSelectedBooks: '$1 books have been selected',
    moveBook: 'Move Book',
    newGroup: 'New Group',
    groupOut: 'Move Out of Group',
    editGroup: 'Edit Group',
    editGroupName: 'Edit Group Name',
    deleteGroup: 'Delete Group',
    deleteGroupTitle: 'After deleting a group, the books in the group will be automatically moved out of the group',
    groupNone: 'There are no books in the current group',
    groupName: 'Group Name',
    confirm: 'Confirm',
    moveBookInSuccess: 'Move book(s) into $1 successfully',
    moveBookOutSuccess: 'Move book(s) out of the group successfully',
    statistic: '$1 public reading • $2 private reading',
    startDownload: 'Start download...',
    progressDownload: 'Downloading：$1',
    downloadFirst: 'Please download book first',
    welcome: 'Welcome to sanwei bookroom ~',
    find: 'Go to book store',
    changeLanguage: 'Change Language',
    toAdmin: 'Go to the library background'
  },
  detail: {
    copyright: 'Copyright',
    navigation: 'Table of Contents',
    publisher: 'Publisher',
    category: 'Category',
    ISBN: 'ISBN',
    trial: 'Trial Reading',
    lang: 'Language',
    loading: 'Loading...',
    read: 'Read',
    listen: 'Listen',
    addOrRemoveShelf: 'Add to Book Shelf',
    isAddedToShelf: 'Added to BookShelf'
  },
  speak: {
    voice: 'Voice Reading',
    read: 'Read Originial',
    settings: 'Settings',
    timing: 'Timing',
    current: 'Current Section',
    requestFailed: 'Request failed!',
    apply: 'The core technology of semantic analysis is provided by iFLY TEK'
  },
  book: {
    pulldownAddMark: 'Pull down to add bookmark',
    releaseAddMark: 'Release to add bookmark',
    pulldownDeleteMark: 'Pull down to delete bookmark',
    releaseDeleteMark: 'Release to add bookmark',
    selectFont: 'Select Font',
    haveRead: 'Already read $1 minutes',
    themeDefault: 'Default',
    themeGold: 'Grace',
    themeEye: 'Eye',
    themeNight: 'Night',
    loading: 'Loading...',
    navigation: 'Contents',
    bookmark: 'Bookmark',
    searchHint: 'Search from the entire book',
    haveRead2: 'already read',
    minutes: 'minutes',
    cancel: 'Cancel'
  }
}

export default messages
