import axios from 'axios'
// import { showFullScreenLoading, tryHideFullScreenLoading } from '../utils/loading'

export function request(config) {
  // 1.创建axios的实例
  const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 5000
  })

  // 2.axios的拦截器
  // 2.1.请求拦截的作用
  instance.interceptors.request.use(config => {
    // 执行loading
    // showFullScreenLoading()
    // console.log(config, '全局拦截')
    return config
  }, err => {
    // console.log(err)
    if (err) {}
  })

  // 2.2.响应拦截
  instance.interceptors.response.use(res => {
    // 关闭loading
    // tryHideFullScreenLoading()
    // console.log(res, '全局响应')
    return res.data
  }, err => {
    // console.log(err)
    if (err) {}
  })

  // 3.发送真正的网络请求
  return instance(config)
}
