import {
  request
} from './request'
import {
  setLocalForage
} from '@/utils/localForage'
// import { getCategoryName } from '@/utils/store'
import axios from 'axios'

export function getHome() {
  return request({
    url: '/book/home',
    method: 'get'
  })
}
export function getList() {
  return request({
    url: '/book/list',
    method: 'get'
  })
}
export function getCategoryList(categoryName) {
  return request({
    url: '/book/CategoryList',
    method: 'get',
    params: {
      fileName: categoryName
    }
  })
}
export function getDetail(book) {
  return request({
    url: '/book/detail',
    method: 'get',
    params: {
      fileName: book.fileName
    }
  })
}
export function flatList() {
  return request({
    method: 'get',
    url: '/book/flat-list'
  })
}

export function shelf() {
  return request({
    url: '/book/shelf',
    method: 'get'
  })
}

export function downloadMp3(url, cb, cb2) {
  axios.create({
    baseURL: url,
    method: 'get',
    responseType: 'blob',
    timeout: 30 * 1000,
    onDownloadProgress: progressEvent => {
      // console.log(progressEvent)
    }
  }).get().then(response => {
    const blob = new Blob([response.data])
    if (cb) cb(blob)
  }).catch(err => {
    if (cb2) cb2(err)
  })
}

export function download(book, onSuccess, onFailed, onError, onProgress) {
  axios.create({
      baseURL: process.env.VUE_APP_EPUB_URL,
      method: 'get',
      responseType: 'blob',
      timeout: 180 * 1000,
      onDownloadProgress: progressEvent => {
        if (onProgress) onProgress(progressEvent)
      }
    }).get(`${book.categoryText}/${book.fileName}.epub`)
    .then(res => {
      // 新建一个blob对象 epub可以直接进行解析，将其存储到indedxedDb
      const blob = new Blob([res.data])
      setLocalForage(book.fileName, blob, () => {
        if (onSuccess) onSuccess(book)
      }, err => {
        if (onFailed) onFailed(err)
      })
    })
    .catch(err => {
      if (onError) onError(err)
    })
}
