import { mapGetters, mapActions } from 'vuex'
import { themeList, addCss, removeAllCss, getReadTimeByMinute } from '@/utils/book'
import { showBookDetail, appendAddToShelf, computedId, removeAddFromShelf, addToShelf } from '@/utils/store'
import { saveLocation, getBookmark, getBookShelf } from '@/utils/localStorage' // ,saveBookShelf
import { shelf } from '@/api/store'

export const ebookMixin = {
  computed: {
    ...mapGetters([
      'fileName',
      'menuVisible',
      'settingVisible',
      'defaultFontSize',
      'defaultFontFamily',
      'fontFamilyVisible',
      'defaultTheme',
      'bookAvailable',
      'progress',
      'section',
      'isPaginating',
      'currentBook',
      'navigation',
      'cover',
      'metadata',
      'paginate',
      'pagelist',
      'offsetY',
      'isBookmark',
      'speakingIconBottom'
    ]),
    themeList() {
      return themeList(this)
    },
    getSectionName() {
      return this.section ? (this.navigation[this.section] ? (this.navigation[this.section].label ? this.navigation[this.section].label : '') : '') : ''
    }
  },
  methods: {
    ...mapActions([
      'setFileName',
      'setMenuVisible',
      'setSettingVisible',
      'setDefaultFontSize',
      'setDefaultFontFamily',
      'setFontFamilyVisible',
      'setDefaultTheme',
      'setBookAvailable',
      'setProgress',
      'setSection',
      'setIsPaginating',
      'setCurrentBook',
      'setNavigation',
      'setCover',
      'setMetadata',
      'setPaginate',
      'setPagelist',
      'setOffsetY',
      'setIsBookmark',
      'setSpeakingIconBottom'
    ]),
    // 翻页隐藏
    hideTitleAndMenu() {
      this.setMenuVisible(false) // 隐藏底部菜单栏
      this.setSettingVisible(-1) // 隐藏设置栏
      this.setFontFamilyVisible(false) // 隐藏设置字体弹窗
    },
    initGlobalStyle() {
      removeAllCss()
      switch (this.defaultTheme) {
        case 'Default':
          addCss(`${process.env.VUE_APP_RES_URL}/theme/theme_default.css`)
          break
        case 'Eye':
          addCss(`${process.env.VUE_APP_RES_URL}/theme/theme_eye.css`)
          break
        case 'Gold':
          addCss(`${process.env.VUE_APP_RES_URL}/theme/theme_gold.css`)
          break
        case 'Night':
          addCss(`${process.env.VUE_APP_RES_URL}/theme/theme_night.css`)
          break
        default:
          addCss(`${process.env.VUE_APP_RES_URL}/theme/theme_default.css`)
          break
      }
    },
    refreshLocation() {
      // 翻页 进度条 滑动翻页 切换章节、保存章节位置信息
      // 获取当前位置对象
      const currentLocation = this.currentBook.rendition.currentLocation()
      if (currentLocation && currentLocation.start) {
        const startCfi = currentLocation.start.cfi
        // console.log(currentLocation.start.cfi) // 获取本章第一个字符位置
        const progress = this.currentBook.locations.percentageFromCfi(startCfi)
        this.setProgress(Math.floor(progress * 100)) // 存储进度条进度
        this.setSection(currentLocation.start.index) // 存储标题信息
        saveLocation(this.fileName, startCfi)
        // 控制页面书签
        const bookmark = getBookmark(this.fileName)
        if (bookmark) {
          if (bookmark.some(item => item.cfi === startCfi)) {
            this.setIsBookmark(true)
          } else {
            this.setIsBookmark(false)
          }
        } else {
          this.setIsBookmark(false)
        }
      }
    },
    display(target, callBack) {
      // console.log(this.currentBook)
      if (target) {
        this.currentBook.rendition.display(target).then(() => {
          this.refreshLocation()
          callBack && callBack()
        })
      } else {
        this.currentBook.rendition.display().then(() => {
          this.refreshLocation()
          callBack && callBack()
        })
      }
    },
    getReadTimeText() {
      return this.$t('book.haveRead').replace('$1', getReadTimeByMinute(this.fileName))
    }
  }
}

export const StoreHomeMixin = {
  computed: {
    ...mapGetters([
      'offsetY',
      'hotSearchOffsetY',
      'flipCardVisible',
      'ifShowBookCard'
    ])
  },
  methods: {
    ...mapActions(['setOffsetY', 'setHotSearchOffsetY', 'setFlipCardVisible', 'setIfShowBookCard']),
    showBookDetail(item) {
      showBookDetail(this, item)
    }
  }
}

export const storeShelfMixin = {
  computed: {
    ...mapGetters([
      'isEditMode',
      'shelfList',
      'shelfSelected',
      'shelfTitleVisible',
      'offsetY',
      'shelfCategory',
      'currentType'
    ])
  },
  methods: {
    ...mapActions(['setIsEditMode', 'setShelfList', 'setShelfSelected', 'setShelfTitleVisible', 'setOffsetY', 'setShelfCategory', 'setCurrentType']),
    showBookDetail(item) {
      showBookDetail(this, item)
    },
    // 获取书架信息
    getShelfList() {
        const shelfList = getBookShelf('shelf')
        console.log(shelfList)
        if (!shelfList) {
          shelf().then(res => {
          if (res && res.bookList) {
            res.bookList.forEach(book => {
                addToShelf(book)
              })
              return this.setShelfList(getBookShelf('shelf'))
            }
          })
        } else {
          return this.setShelfList(shelfList)
        }
    },
    getCategoryList(title) {
      this.getShelfList().then(() => {
        const categoryList = this.shelfList.filter(book =>
          book.type === 2 && book.title === title)[0]
        // console.log(categoryList)
        this.setShelfCategory(categoryList)
      })
    },
    moveOutOfGroup(fn) {
      this.setShelfList(this.shelfList.map(book => {
        if (book.type === 2 && book.itemList) {
          book.itemList = book.itemList.filter(subBook => !subBook.selected)
        }
        return book
      })).then(() => {
        const list = computedId(appendAddToShelf([].concat(removeAddFromShelf(this.shelfList), ...this.shelfSelected)))
        this.setShelfList(list)
        this.simpleToast(this.$t('shelf.moveBookOutSuccess'))
        fn && fn()
      })
    }
  }
}
