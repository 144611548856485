const store = {
  state: {
    hotSearchOffsetY: 0,
    flipCardVisible: false,
    ifShowBookCard: false,
    isEditMode: false, // 是否进入编辑模式
    shelfList: [], // 书架图书列表
    shelfSelected: [], // 书架图书选中列表
    shelfTitleVisible: true, // 书架标题显示状态
    shelfCategory: {}, // shelfCategory.itemList页面数组，书架的分组/分类数据
    currentType: 1 // shelf页面为1，categoty为2
  },
  mutations: {
    SET_HOT_SEARCH_OFFSETY(state, offsetY) {
      state.hotSearchOffsetY = offsetY
    },
    SET_FLIP_CARD_VISIBLE(state, visible) {
      state.flipCardVisible = visible
    },
    SET_IF_SHOW_BOOK_CARD(state, ifshow) {
      state.ifShowBookCard = ifshow
    },
    SET_IS_EDIT_MODE(state, isEditMode) {
      state.isEditMode = isEditMode
    },
    SET_SHELF_LIST(state, list) {
      state.shelfList = list
    },
    SET_SHELF_SELECTED(state, selected) {
      state.shelfSelected = selected
    },
    SET_SHELF_TITLE_VISIBLE(state, visible) {
      state.shelfTitleVisible = visible
    },
    SET_SHELF_CATEGORY (state, category) {
      state.shelfCategory = category
    },
    SET_CURRENT_TYPE (state, type) {
      state.currentType = type
    }
  }
}

export default store
